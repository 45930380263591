import { useMutation } from "@apollo/client"
import React, { useState } from "react"
import { toast } from "react-toastify"
import Contact from "../components/Contact"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import { Posting } from "../crud/createRegister"

const ForumPost = () => {
  const [post, setPost] = useState({
    Category: "-",
    Title: "",
    Description: "",
    Nick: "",
    Email: "",
    messageError: {},
    isVerified: true,
  })
  const [loading, setLoading] = useState(false)
  const { messageError } = post
  const handleChange = e => {
    const { name, value } = e.target
    setPost(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = post.messageError
    switch (name) {
      case "Category":
        errors.Category =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "Title":
        errors.Title =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "Description":
        errors.Description =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "Nick":
        errors.Nick =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }
  const [addPost] = useMutation(Posting, {
    onCompleted: () => {
      toast.success("All Ok")
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })
  const handlePost = () => {
    addPost({
      variables: {
        input: {
          data: {
            post_category: post.Category,
            title: post.Title,
            description: post.Description,
            nickname: post.Nick,
            email: post.Email,
          },
        },
      },
    })
  }
  /*const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setPost(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }*/
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (post.isVerified) {
      if (
        post.Category !== "-" &&
        post.Title !== "" &&
        post.Description !== "" &&
        post.Nick !== ""
      ) {
        if (formValidate(post.messageError, post)) {
          setLoading(true)
          handlePost()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      //recaptchaVerify()
    }
  }
  return (
    <>
      <section className="container forum-content">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <SectionTitle>メキシコ生活お役立ち掲示板投稿フォーム</SectionTitle>
            <hr className="u-line-bottom" />
            <form className="forum-post" onSubmit={handleSubmit}>
              <div className="forum-post_input">
                <select id="Category" name="Category" onChange={handleChange} onBlur={handleChange}>
                  <option value="-">-</option>
                  <option value="1">お仕事</option>
                  <option value="2">生活</option>
                  <option value="3">住居</option>
                  <option value="4">売ります</option>
                  <option value="5">買います</option>
                  <option value="6">イベント情報</option>
                </select>
              </div>
              <div className="forum-post_input">
                <input
                  type="text"
                  placeholder="Title"
                  className="w100"
                  id="Title"
                  name="Title"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.Title && (
                  <span className="field-validation">{messageError.Title}</span>
                )}
              </div>
              <div className="forum-post_input">
                <textarea
                  type="textarea"
                  placeholder="Detail of Post"
                  id="Description"
                  name="Description"
                  onChange={handleChange}
                  onBlur={handleChange}
                ></textarea>
                {messageError.Description && (
                  <span className="field-validation">
                    {messageError.Description}
                  </span>
                )}
              </div>
              <div className="forum-post_input">
                <input
                  type="text"
                  placeholder="Your Nickname"
                  className="w50"
                  id="Nick"
                  name="Nick"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.Nick && (
                  <span className="field-validation">{messageError.Nick}</span>
                )}
              </div>
              <div className="forum-post_input">
                <input
                  type="text"
                  placeholder="Email(Optional, not public)"
                  className="w50"
                  id="Email"
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </div>
              <div className="u-text-center">
                {loading === false ? (
                  <input type="submit" value="投稿" className="btn-submit" />
                ) : (
                  <p class="buttonload">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    &nbsp;&nbsp;少々お待ちください
                  </p>
                )}
              </div>
            </form>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default ForumPost
